* {
    margin: 0;
    box-sizing: border-box;
}

main {
    /* padding: 20px; */
    font-family: "Montserrat", sans-serif;
    /* min-width: 900px; */
    width: 100%;
    min-height: 100vh;
    background: radial-gradient(#e66465, #9198e5);
    background-size: contain;
    overflow: none;

    /* animation: colorLoop 3s infinite ease; */
}

body {
    background: radial-gradient(#e66465, #9198e5);
}

.smallHeader {
    font-size: 14px;
}

#app-container {
    width: 100%;
    min-height: 100vh;
    display: grid;
    place-items: center;
    color: white;
}

#landing-container {
    min-height: 670px;
    margin: 10px;
    display: flex;
    place-items: center;
    width: 50%;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}

#impressum {
    width: 50%;
}

#events {
    padding-top: 20px;
    /* max-height: 80vh; */
    overflow-y:hidden ;
    z-index: -20;
}

#eventHeadliner {
    border-bottom: 1px solid white;
    padding: 10px;
    padding-left: 0px;
    text-shadow: 1px 1px black;
    display: flex;
    z-index: 30000000;
}

#eventHeadliner * { 
    margin-right: 20px;
    padding: 5px;
    cursor: pointer;
}

#eventHeadliner h1:hover {
    background-color: rgba(245, 245, 245, 0.399);
}

#eventsContainer {
    padding: 43px;
    width: 100%;
    min-height: 100vh;
}

#eventsContainer input {
    margin: 10px;
    width: 35%;
    height: 30px;
    box-shadow: 1px 1px 6px ghostwhite, -1px -1px 6px ghostwhite;
    padding-left: 15px;
    font-size: 15px;
    background-color: transparent;
    color: white;
}

#eventSearch::placeholder {
    color: white;
    text-shadow: 1px 1px black;
}

.event {
    /* background-color: rgba(248, 248, 255, 0.762); */
    border-bottom: 1px solid rgba(255, 255, 255, 0.708);
    width: 100%;
    margin: 5px;
    margin-bottom: 15px;
    padding: 5px;
    color: white;
    display: flex;
}

#thisMonthView {
    margin: 5px 0 10px 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
#thisMonthView img {
    margin: 2px 5px;
}

.singleEvent {
    width: 90%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    padding: 15px 0 20px 0;
    /* max-height: 90vh; */
  
    /* justify-content: center; */
}

.socialLogo { 
    margin: 5px;
}

.eventImage{
    margin-top: 20px;
}

.eventDescription {
    white-space: pre-wrap;
    margin-bottom: 20px;
}

#eventName {
    margin-bottom: 20px;
}

.first {
    width: 30%;
    /* padding: 3px; */
}

input {
    margin: 10px;
}

.eventsDate {
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
}

.date {
    width: 20%;
    display: flex;
    justify-content: flex-end;
}

#eventSearch {
    margin: 5px;
    /* border-radius: 20px; */
    border: none;
}

#description {
    margin: 25px;
    margin-top: 25px;
    /* word-spacing: 0.12rem; */
}

#aboutLinks {
    display: flex;
}
#aboutLinks a {
  
       margin: 5px;
}

a {
    /* margin: 5px; */
    text-decoration: none;
    color: white;
    text-shadow: 1px 1px black;
}

.answer {
    max-height: 0;
    overflow: scroll;
    position: relative;

    transition: max-height 700ms ease;
}

:target .answer {
    max-height: 20rem;
}

.ion-md-arrow-down {
    display: none;
}

:target .event .ion-md-arrow-forward {
    display: none;
}

:target .event .ion-md-arrow-down {
    display: flex;
    justify-content: flex-end;
}

#logo {
    cursor: pointer;
    max-width: 1000px;
}

#singleEventImage {
    max-width: 700px;
}

#back {
    position: absolute;
    font-size: 20px;
    top: 2px;
    left: 5px;
    opacity: 80%;
}

#open {
    position: absolute;
    font-size: 20px;
    margin: 6px;
    top: 2px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#openDot {
    width: 0.75rem;
    height: 0.75rem;
    background-color: green;
    border-radius: 50%;
    margin-right: 7px;
    margin-top: 2px;
    animation: blink 2s infinite;
}
#closed {
    position: absolute;
    font-size: 20px;
    margin: 6px;
    top: 2px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#closedDot {
    width: 0.75rem;
    height: 0.75rem;
    background-color: grey;
    border-radius: 50%;
    margin-right: 7px;
    margin-top: 2px;
}

@keyframes colorLoop {
    0%,
    50% {
        background: radial-gradient(#e66465, #9198e5);
    }
    25%,
    75% {
        background: radial-gradient(#66c40d, #9198e5);
    }
}

@keyframes blink {
    0%,
    100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}

@media (max-width: 770px) {
  #landing-container {
   width: 95%;
   min-height: 0;
   display: grid;
   place-items: center;
  }
  #back {
      font-size: 14px;
  }
}

@media screen and (prefers-color-scheme: dark) {
    body {
        background: black;
    }
}
